.content{
  padding-bottom:20px;
  border-style: solid;
  padding: 20px;
  border-radius: 25px;
  border-color: darkcyan;
  cursor: pointer;
}

.time h4{
  margin:0;
  padding:0;
  font-size:14px;
}

.odd-time
{
  position:absolute;
  top:12px;
  right:-165px;
  margin:0;
  padding:8px 16px;
  background:rgba(233,33,99,1);
  color:#fff;
  border-radius:18px;
  box-shadow:0 0 0 3px rgba(233,33,99,0.3);
}

.even-time
{
  position:absolute;
  top:12px;
  left:-165px;
  margin:0;
  padding:8px 16px;
  background:rgba(233,33,99,1);
  color:#fff;
  border-radius:18px;
  box-shadow:0 0 0 3px rgba(233,33,99,0.3);
}

.content h3{
  padding:0;
  margin:0;
  color:rgba(233,33,99,1);
  font-weight:600;
  text-align: center;
}
.content p{
  margin:10px 0 0;
  padding:0;
}

@media(max-width:767px){
  .odd-time,
  .even-time {
    top:-30px;
    left:50px;
    right:inherit;
  }
}